//@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: 'kyri_kapsregular';
  src: url('/assets/fonts/kyri_kaps-webfont.woff2') format('woff2'),
      url('/assets/fonts/kyri_kaps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$wayred:#e6613e;
$wayblue: #4295CF;
$waygreen: #17AF88;
$waypurple: #8378A3;
$corner-radius: 8px;

$max-window-size: 500px; //400px;
$max-mobile-window-size: 370px;

#leva__root{

  z-index: 9999999;
  position: absolute;
}

* {
    box-sizing: border-box;
  }
  
  html,
  body{
    position: fixed;
  }

 body{
  background-color: yellow;
 // cursor:grab !important;
  }

  body.drag-cursor{
   // cursor:grabbing !important;
  }
  
  html,
  body,
  #root,
  #WholeThing{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
   
   font-family: 'kyri_kapsregular', sans-serif;
   overscroll-behavior: none;
   
  }

  h1{
    font-size: 64px;
  }

  h2{
    font-size: 36px;
  }
  h3{
    font-size: 28px;
  }
  h4{
    font-size: 24px;
  }
  h5{
    font-size: 18px;
  }

  .snapscreen{
    width: 100vw;
    height: 100vh;
    opacity: .6;
    background-color: white;
    position: absolute;
    top: 0;
    left:0;
    z-index: 999999;
    pointer-events: none;
  }

  #threeCanvas{
    z-index: 9999;
    position: absolute !important;
    top: 0;
    left: 0;

   
  }

  .polaroid{
    position: absolute;
   // top:0;
   // right:0;
    top: calc(50vh - 300px);
    left: calc(50vw - 300px);

   // border: 5px solid white;
    z-index: 0;//9999;
    //width: 300px;
   // height: 300px;
    width: 600px;
    height: 600px;
    box-sizing: content-box;
    margin: 0;
    padding: 0;

    .tempSnap{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      
    }

    .tempOverlay{
      position: absolute;
      top:0;
      left:0;
      background:transparent;
     
    }


  }

  .show{
    display: block;
 
  
  }
  .hide{
    display: none;
  }

  .clickable{
    pointer-events: all;
  }

  .no-clickable{
    pointer-events: none;
  }

  #polaroid{
    width: 100%;
    max-width: $max-window-size;
    
    img {
      width: 100%
    }
  }


  .sound-button{
    width: 40px;
    height: 40px;
    margin: 4px auto;
    border-radius: 6px;
    border-color: white;
    border-width: 0;
    background-color: #ffd200;
    
    cursor: pointer;
    position: absolute;
  //  top: 10px;
  //  right: 10px;

    right: 16px;
    top: 12px;
    z-index: 9999999;
    transform: translateY(0px);
    transition: transform .2s ease-out;
     
    
      svg{
        width: 20px;
        height: 20px;
        margin: 0 auto;
        transform: translateX(-1px);
        fill: white;
      }
    
      &:hover{
        background-color: #ffe403;
        transform: translateY(1px);
      }
    }
    
  

  .introMessage{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;

     & > div{
      width: 100% !important;
      height: 100% !important;
     }

     h3{
      text-shadow: 2px 2px white
     }

      @media (max-width: 580px) {
        padding: 20px 10px;
       // transform: scale(.5);
   
      
          h3{
            margin: 0px 0 20px;
            font-size: 20px;
          }
        
      }

      button{
        font-family: 'kyri_kapsregular', sans-serif;
        font-size: 24px;
        color: white;
        background-color: orange;
        padding: 10px 30px;
        border: none;
        border-radius: 10px;
        white-space: nowrap;
        user-select: none;
        overflow: hidden;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:hover{
          background-color: rgb(238, 155, 2);
        }
      }
  }

  .webcam-holder{
   // border: 1px solid red;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);

 

  }

  .webcam-holder.shown{
    display: block;
  }

  .webcam-holder.hidden{
    display: none;

    
  }

  @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {

     .webcam-holder.hidden{
       display: block;
       
     }
  }

  #webcam{
  //  transform: translateX(-50px);
   // display: none;
  }

  .intro-window{
    
    .preview{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;



      button{
        font-family: 'kyri_kapsregular', sans-serif;
        font-size: 24px;
        color: white;
        background-color: orange;
        padding: 10px 30px;
        border: none;
        border-radius: 10px;
      }
    }
  }

  .intro-window,
  .selfie-window{
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100dvh;
    height: 100%;
    justify-content: center;
    align-items: center;
   // opacity: .4;

    @media (max-width: 767px) {
      margin-top: -10%;//-80px;

    }
   
  }

  .round-edge{
    border-radius: $corner-radius;
  }

  
  .preview {
    display: flex;
    flex-direction: column;
    position: relative;
   // position: absolute;
   // top: calc(100vh - 600px);//50%;
  //  left: calc(100vw - 600px);//50%;
    //transform: translate(-50%, -50%) scale(2);
   // border: 10px solid white;
    outline: 10px solid white;

    //opacity: 0.2;
    
    //background-color: #faea0b87;


    background: radial-gradient(ellipse at top, #ff63005e, #ffeeb073), radial-gradient(ellipse at bottom, #86ffa875, #cad79f40);
 
   // overflow: hidden;
    border-radius: 8px;
   // background: transparent;
    z-index: 999;
   min-height: 300px;
   min-width: 300px;
   box-sizing: content-box;

   max-height: none;
   max-width: none;

   @media (max-width: 767px) {
   //  width: 370px !important;
   //  height: 370px !important;

   }
  
  }

  .intro-window .preview{
    box-sizing: border-box;
  }

  video {
   // max-width: 400px;
    height: auto;
   // transform: scale(-1, 1);
  
  }
  /*
  .preview canvas {
    transform: scale(-1, 1);
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
  
    
  } 
  */
  .guides{
    position: absolute !important;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    pointer-events: none;
  }

 

  .background{
    position: absolute !important;
    transform: translateZ(0px);
    overflow: hidden;
    
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 1;
 //   background-color: white;
    transition: background-color 1s ease-out 1s;

  

    .bkgd-img{
      object-fit: cover;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      margin: -1;
      //borderRadius: theme.shape.borderRadius,
    }
  }

  .selfie{
    position: absolute !important;
    
    bottom: 0;
    left: 0;//100px!important;
    height: 100%;
    width: 100%;
    z-index: 3;
    border-radius: $corner-radius;
    //border: 2px solid orange;

   @media (max-width: 767px) {
   // width: 370px !important;
   // height: 370px !important;

  }
  }
  
  .control-panel{
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 9999999;
  }

  .snapshot-panel{
    border: 0;//10px solid white;
    padding: 20px;
   // padding-bottom: 80px;
    z-index: 999999;
    position: absolute;
   // top: 0;
  //  left: 0;
    top: calc((100vh - ($max-window-size + 100px))/2 );
    left: calc((100vw - $max-window-size)/2 );
   // transform: rotate(-5deg);
   // background-color:rgba(255, 255, 255, 1);

    background-image: url( '/assets/images/gold-glitter.jpg');
    background-size: contain;
    pointer-events: none;

    @media (max-width: 580px) {
      left: calc((100vw - $max-mobile-window-size)/2 );
   
     }

 
  }

  .snapTag{

    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 34px;
    margin: 14px 0;
    color: white;
    font-weight: bolder;
    //text-shadow: 0px -1px 0px white, 0px 0px 5px #757247;
    text-shadow: 0px -1px 0px white, 0px 3px 1px #6c6b5c;
    letter-spacing: 1px;
    font-size: 35px;
    margin-bottom: 10px;

    @media (max-width: 580px) {
      font-size: 25px;
      letter-spacing: 0px;
      margin-bottom: 5px;
   
     }
  }

  .snapTag-site{
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: -2px;
    margin: 2px 0;

    position: absolute;
    left: 50%;
    bottom: 10px;
    margin: 2px 0;
    max-width: 400px;
    transform: translate(-50%, 0);
   // font-size: 10px;
   // letter-spacing: 0.5px;
   // color: #666;
  }
  
  
  .picker {
    position: relative;
  }
  
  .swatch {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 4px 0;

    
  }
  
  .popover {
    position: absolute;
    top: 4px;
    left: calc(100% + 6px);
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 9999;
  }
  
  .stroke-value{
    font-size: 12px;
    padding: 10px;
    border: 1px solid white;
    background-color: white;
    border-radius: 6px;
    margin: 4px 0;
    text-align: center;
    font-weight: bold;
    color: #010101;
  
  }
  
  
  
  .logoBox{
  
     cursor: pointer;
     position: absolute;
      z-index: 999;
      top: 10px;
      left: 10px;
  }
    .logoHolder{
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: overflow-wrap;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
    }
  
      .logoCircle{
        position: absolute;
        width: 80%;
        height: 80%;
        background: purple;
        border-radius: 50%;
        margin-left: -4px;
        cursor: pointer;
        box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
        will-change: width, height;
      }
  
      .letter{
        width: 60px;
        height: 60px;
        background: white;
        border-radius: 100px;
        background-size: contain;
        /* border: 1px solid pink;*/
        will-change: transform, opacity;
      }
    
  
.ControlBar{
    background-color: rgba(0, 0, 0, 0.334);
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    padding: 10px 2px 10px 10px;
    transform: translateY(-50%);
    border-top-right-radius: 8px 8px;
    border-bottom-right-radius: 8px 8px;

    .button-col{
        display: flex;
    flex-direction: column;
    }

    .slider-col{
        padding: 10px 0 5px 4px;
    }

    button{
        width: 40px;
        height: 40px;
        margin: 4px 0;
        border-radius: 6px;
        border-color: rgb(255, 255, 255);
        border-width: 0;
        background-color: white;
        cursor: pointer;

        &:hover{
            background-color: rgb(240, 255, 203);
        }

        svg{
            width: 20px;
            height: 20px;
        }
    }
}  

.bg {
  position:relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  will-change: opacity;
 // border: 1px solid #b59c10;

}

.bg2{
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

.btn{
  pointer-events:all;
  cursor: pointer;

}

.load-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-bar {
  position: absolute;
  pointer-events: none;

  left: 50%;
  z-index: 999999;
  //border: 1px solid green;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 50%);

  @media (max-width: 767px) {

   // transform: translate(-50%, 80%);
    transform: translate(-50%, 0%) scale(.725);

  }

  > div{
   
    width: 100%;
    display: flex;
    justify-content: center;
    will-change: transform, opacity;
    pointer-events: none;

    > div{
      will-change: transform, opacity;
    }
  }

 
  .closeButton{
    background-image: url('./assets/images/plus.svg');
    border: none;

    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: orange;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    // transform: translate(-50%, -50%);
    
    transform: scale(1) rotate(45deg);
    transition: all .3s ease-out;
    cursor: pointer;
    pointer-events: all;


    &:hover{
      transform: scale(1.1) rotate(-45deg);
    }
  }

  .snapIcon{
    background-image: url('./assets/images/camera.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
  }
  .snapButton {

    
    border: none;

    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: orange;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px;
    // transform: translate(-50%, -50%);
    transform: scale(1);
    transition: all .3s ease-out;
    cursor: pointer;
    pointer-events:all;


    &:hover{
      transform: scale(1.1)
    }
  }

  .phase-btn {
    width: auto;
    height: 70px;
    border-radius: 35px;
    background-color: #4295cf;
    background-repeat: no-repeat;
    background-position: 65% 50%;
    background-size: 40px;
    margin: 10px;
    border: none;
    font-size: 24px;
    color: white;
    padding: 0 40px;
    white-space: nowrap;
    user-select: none;


    transform: scale(1);
    transition: all .25s ease-out;
    cursor: pointer;
    pointer-events: all;

    font-family: kyri_kapsregular,sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    border: 3px solid white;



    &:hover{
      transform: scale(1.03);
      color: yellow;
    }


    &.phase-selected{
      background-color:  #FFE400;
      color: #4295cf;

      &:hover{
        transform: scale(1.03);

      }
    }



  }


  .arrow-btn {
    width: 70px;
    height: 70px;
    background-image: url('./assets/images/arrow.png');
    border-radius: 35px;
    background-color: #4295cf;
    background-repeat: no-repeat;
    background-position: 65% 50%;
    background-size: 40px;
    margin: 10px;
    border: none;

    transform: scale(1);
    transition: all .3s ease-out;
    cursor: pointer;
    pointer-events: all;


    &:hover{
      transform: scale(1.1)
    }
  }

  .arrow-btn.left {
    transform: scale(-1, 1);
    transition: all .3s ease-out;


    &:hover{
      transform: scale(-1.1, 1.1)
    }
  }

  .arrow-btn.down {
    transform: rotate(33.3deg) scale(-1, 1);
    transition: all .3s ease-out;


    &:hover{
      transform: rotate(33.3deg) scale(-1.1, 1.1);
      background-color: orange;
    }
  }
}


.print-bar{


  position: absolute;
  left: calc(50vw + 245px - 25px) !important;
  top: calc(50vh + 10px) !important;
  z-index: 999999999;
  transform: translate(-50%, -50%);
  height: 600px;


  @media (max-width: 767px) {
    left: calc(100vw - 35px) !important;
    top: 65vh !important;
    transform: translate(-50%, -50%) scale(1.3);
  }

  > div{
   
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
   // justify-content: center;

   .download-holder{

    @media (max-width: 580px) { 
      transform: translateY(-130px);
    }
  }


   
  }

}
/// word balloon


.word-balloon{
  // border: 1px solid black;
   font-family: 'kyri_kapsregular', sans-serif;
   font-size: 24px;
   color: black;
   pointer-events: none;
   
  /* padding: 10px 40px;
   border-top-left-radius: 50% 60%;
   border-top-right-radius: 60% 50% ;
   border-bottom-left-radius: 50% 60% ;
   border-bottom-right-radius: 60% 50%;
 */


 
 }
 /*
 .word-balloon.bubble { 
   background-position: center;
   background-repeat: no-repeat !important;
   background-size: 100% 100%;
   margin: 0 auto;
   text-align: center;
   height: 0;
   box-sizing: content-box;
   line-height: 1;
 }
 .word-balloon.speech {
   background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg); 
  // width: 25%;
   padding-top: 6%;
   padding-bottom: 6%;
 }
 */
 
 .word-balloon {
   top: 10%;
   left: 60%;
   font-family: 'kyri_kapsregular', sans-serif;
   font-size: 30px;
   color: black;
   //box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
   padding: 10px 20px;
   border-radius: 50%;
   z-index: 9999999999;
  // border: 1px solid black;
  // max-width: 260px;
  
 // pointer-events: none;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   position: absolute;
   min-width: 340px;
   //min-height: 112px;
   min-height: 162px;

   @media (max-width: 767px) {
    left: 10%;
    font-size: 24px;
    min-width: 300px;
   // min-height: 100px;
    min-height: 162px;
  }
   
 }
 
 .bubble-text {
   z-index: 9999;
   /* outline-style: none;*/
 }
 
 .balloon-body {
   background-color: white;
   border: 1px solid black;
   box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   border-radius: 50%;
   z-index: -2;
   min-width: 340px;
   transform: scale(0);

   @media (max-width: 767px) {
    min-width: 280px;
  }
 }
 
 .DialogWindow{
   text-align:center;
   line-height: 20px;
   padding: 36px 8px;
   line-height: 35px;

   border-radius: 50%;
   overflow: hidden;
  // border: 1px solid red;
 }
 
 .tail {
   z-index: -1;
 
   position: absolute;
   height: 0;
   width: 0;
   opacity: 0;
 
   top: 60%;
   left: 20%;
   transform: rotate(50deg) scaleY(1);
   
   /* box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);*/
   /*  animation: rotation 5s infinite linear;*/
   transform-origin: 50% 0%;
   border-left: 20px solid transparent;
   border-right: 20px solid transparent;
 
   border-top: 80px solid #fff;


   @media (max-width: 767px) {
    transform: rotate(5deg) scaleY(.8);
   }
   
 }
 
 .tail-shadow {
   z-index: -3;
 
   position: absolute;
   height: 0;
   width: 0;
   opacity: 0;
 
   top: 60%;
   left: 20%;
   transform: rotate(50deg) scaleY(1) translateX(0px);
   /* box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);*/
   /*  animation: rotation 5s infinite linear;*/
   transform-origin: 50% 0%;
   border-left: 20px solid transparent;
   border-right: 20px solid transparent;
 
   border-top: 90px solid #000;

   @media (max-width: 767px) {
    transform: rotate(5deg) scaleY(.8);
   }
 }

 
 /////////////
.debug-controls{
  position: absolute;
  bottom:0;
  right: 0;
  z-index: 9999999;
  width: 180px;

  p{
    background-color: white;
  }
}

.debug-controls.on-off{
  left:0;
}

.trailsText {
  position: relative;
  width: 100%;
  height: auto;
 // line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
 // letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.trailsText > div {
 // padding-right: 0.05em;
  overflow: hidden;
  display: flex;
    justify-content: center;
}


.c {
  position: absolute;
  max-width: 500px;
  max-height: 500px;
  width: 350px;
  height: 200px;
  cursor: pointer;
  will-change: transform, opacity;
}

.front,
.back {
  background-size: cover;
}

.back {
  background-image: url(https://images.unsplash.com/photo-1544511916-0148ccdeb877?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1901&q=80i&auto=format&fit=crop);
}

.front {
  background-image: url(https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80&auto=format&fit=crop);
}

.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.title-slide{
  width: 460px;

  @media (max-width: 767px) {
    width: 90%;

   
  }

  @media (max-width: 580px) { 
    width: 70%;
  }

  img{
    width: 100%;
    filter: drop-shadow(0px 4px 0px white)
  }
}

.wpb-game{
  width: 320px;
  margin: 0px 0 20px;

  @media (max-width: 580px) { 
    width: 70%;
  }
}

///countdown


.timer-wrapper {
  display: flex;
  justify-content: center;
  color: white;
}

.timer {
 // font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "kyri_kapsregular", sans-serif;
  font-weight: bold;

  &.emoji{
    font-size: 64px;
  }
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;
}
/*
.button {
  padding: 1rem 2rem;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 6px;
  background-color: #2080df;
}

.button > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
*/
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

